






















import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockContentWithImage extends Vue {
  // TODO: add bg type
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  @Prop(String)
  body!: string;

  @Prop(Boolean)
  largeTitle!: boolean

  @Prop()
  image!: any

  @Prop(String)
  linkPath!: string

  @Prop({ type: String, default: 'Visa alla' })
  linkText!: string

  @Prop(Boolean)
  reverse!: boolean
}
