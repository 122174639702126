












import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockableBlockColorFragment, blockColorMixin } from '@/composables/block-color';

import { ArticleBlockDataFragment } from '@/graphql/generated';
import { PageBlockMixin } from '@/modules/cms';
import { medium1x1ImageFragment } from '@/graphql/queries/image.fragments';

@Component({
  graphqlData: gql`
    fragment ArticleBlockData on ArticleBlock {
      id
      title
      body
      linkText
      linkUrl
      imagePosition
      image {
        ...Medium1x1ImageData
      }
      ...BlockColor
    }

    ${medium1x1ImageFragment}
    ${blockableBlockColorFragment}
  `,
})
export default class ArticleBlock extends mixins(PageBlockMixin<ArticleBlockDataFragment>(), blockColorMixin) {}
